<template>
  <div class="uk-container login-user">
    <UcariHeader />

    <h1>Account Registration Confirmation</h1>

    <p>Thank you for signing up. We've sent you an email to verify your email address. Please check your spam or junk mail folder to make sure you don't miss any of our emails. </p>

  </div>
  <UcariFooter />
</template>

<script>
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  created() {
    document.title = 'Account Registration Confirmation | UCARI';
  },  
  components: {
    UcariHeader,  
    UcariFooter
  }
}
</script>
