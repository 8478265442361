<template>
  <div class="uk-container welcome">
    <UcariHeader />

    <div class="uk-text-center uk-margin-bottom">
      <h1>Page Not Found</h1>
      <p>We're sorry, but the page you were looking for doesn't exist.</p>
      <router-link to="/">Go back to Home</router-link>
    </div>
  </div>

  <UcariFooter />
</template>

<script>
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  created() {
    document.title = 'Welcome | UCARI';
  },
  components: {
    UcariHeader,
    UcariFooter
  }
};
</script>
