<template>
  <div class="uk-container register-kit">
    <div class="uk-margin-top uk-margin-bottom">
      <a href="https://ucari.com/">
        <img src="/images/logo.png" alt="UCARI Logo" width="100">
      </a>
    </div>
    <h1>Kit Registered Confirmation</h1>
    <p>Thank you for registering your test kit. We process and post your results within two business days from receipt of your sample from the United States Postal Service (USPS). It is common for the USPS to take up to 10 business days to deliver first-class mail. As soon as we receive your sample, you are sent an email letting you know it was received.</p>

    <div class="uk-margin-top">
      <router-link to="/">
        <button class="uk-button uk-button-primary">Go to Your Account</button>
      </router-link>      
    </div>    
  </div>
</template>
