<template>
  <div class="uk-container register-user">
    <UcariHeader />
    <form @submit.prevent="register">
      <h2>Create Your UCARI Account</h2>
      <p>Create a free account to register a kit and view your test results.</p>  
      <div v-if="message">
        <p>{{ message }}</p>
      </div>
      <div>
        <div class="uk-margin">
          <label for="email">Email</label>
          <input class="uk-input" :class="{ 'error-border': (isEmailInvalid || !email) && (emailTouched || formSubmitted) }" type="email" v-model="email" @blur="validateEmail" @focus="emailTouched = true" />
          <div class="error" v-if="emailMessage">{{ emailMessage }}</div>
        </div>
        <div class="uk-margin">
          <label for="firstName">First Name</label>
          <input class="uk-input" :class="{ 'error-border': (!firstName) && (firstNameTouched || formSubmitted) }" type="text" v-model="firstName" @focus="firstNameTouched = true" />
        </div>
        <div class="uk-margin">
          <label for="lastName">Last Name</label>
          <input class="uk-input" value="" :class="{ 'error-border': (!lastName) && (lastNameTouched || formSubmitted) }" type="text" v-model="lastName" @focus="lastNameTouched = true" />
        </div>
        <div class="uk-margin">
          <label for="password">Password</label>
          <div class="uk-inline">
            <input :type="showPassword ? 'text' : 'password'" class="uk-input" :class="{ 'error-border': (isPasswordInvalid || !password) && (passwordTouched || formSubmitted) }" v-model="password" @input="handlePasswordInput" @focus="passwordTouched = true" value="" />
            <a class="uk-form-icon uk-form-icon-flip" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</a>
          </div>
        </div>
        <div class="password-requirements">
          <div><strong>Password must contain:</strong></div>
          <ul>
            <li :class="{ 'valid': passwordHasLowercase }">One lowercase letter</li>
            <li :class="{ 'valid': passwordHasUppercase }">One uppercase letter</li>
            <li :class="{ 'valid': passwordHasNumber }">One number</li>
            <li :class="{ 'valid': passwordHasSpecialChar }">One of these special character: ! @ # $ % ^ & * ( ) - + { } | ?</li>
            <li :class="{ 'valid': passwordHasLength }">At least 8 characters</li>
          </ul>
        </div>
        <div class="uk-margin">
          <label for="confirmPassword">Confirm Password</label>
          <div class="uk-inline">
            <input :type="showConfirmPassword ? 'text' : 'password'" class="uk-input" :class="{ 'error-border': (passwordMismatch || !confirmPassword) && (confirmPasswordTouched || formSubmitted) }" v-model="confirmPassword" @input="handlePasswordInput" @focus="confirmPasswordTouched = true" />
            <a class="uk-form-icon uk-form-icon-flip" @click="toggleConfirmPasswordVisibility">{{ showConfirmPassword ? 'Hide' : 'Show' }}</a>
          </div>
          <div class="error" v-if="passwordMismatch">{{ passwordMismatch }}</div>
        </div>
        <div>
          By creating an account, you agree to our <a href="https://ucari.com/termsofservice" target="_blank">Terms of service</a> and <a href="https://ucari.com/privacy" target="_blank">Privacy policy</a>.
        </div>
        <button class="uk-button uk-margin" type="submit" :disabled="isFormInvalid || loading">
          Register
          <span v-if="loading" class="uk-margin-small-left" uk-spinner="ratio: 0.6"></span>
        </button>
        <div>Already a customer? <router-link to="/login">Sign In</router-link></div>
      </div>
    </form>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 
import { validatePassword, toggleVisibility } from '@/utils/common';

export default {
  created() {
    document.title = 'Create Account | UCARI';
  },  
  components: {
    UcariHeader,  
    UcariFooter
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      message: '',
      emailMessage: '',
      passwordMismatch: '',
      isEmailInvalid: false,
      isPasswordInvalid: false,
      showPassword: false,
      showConfirmPassword: false,
      emailTouched: false,
      firstNameTouched: false,
      lastNameTouched: false,
      passwordTouched: false,
      confirmPasswordTouched: false,
      formSubmitted: false
    };
  },
  computed: {
    isFormInvalid() {
      return this.isEmailInvalid || this.isPasswordInvalid || this.password !== this.confirmPassword || !this.email || !this.firstName || !this.lastName || !this.password || !this.confirmPassword;
    },
    passwordHasLowercase() {
      return /[a-z]/.test(this.password);
    },
    passwordHasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    passwordHasNumber() {
      return /\d/.test(this.password);
    },
    passwordHasSpecialChar() {
      return /[!@#$%^&*()\-+{}|:;<>?/\\]/.test(this.password);
    },
    passwordHasLength() {
      const minLength = 8;
      return this.password.length >= minLength;
    }
  },
  methods: {
    async validateEmail() {
      if (this.email === "") return;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        this.emailMessage = 'Invalid email address';
        this.isEmailInvalid = true;
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/check-email`, {
          email: this.email
        });
        if (response.data.exists) {
          this.emailMessage = 'Email already registered';
          this.isEmailInvalid = true;
        } else {
          this.emailMessage = '';
          this.isEmailInvalid = false;
        }
      } catch (error) {
        this.emailMessage = 'Error: ' + error.response.data.error;
        this.isEmailInvalid = true;
      }
    },
    handlePasswordInput() {
      const { isPasswordInvalid, passwordMismatch } = validatePassword(this.password, this.confirmPassword);
      this.isPasswordInvalid = isPasswordInvalid;
      this.passwordMismatch = passwordMismatch;
    },
    togglePasswordVisibility() {
      this.showPassword = toggleVisibility(this.showPassword);
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = toggleVisibility(this.showConfirmPassword);
    },
    async register() {
      this.formSubmitted = true;

      if (this.isFormInvalid) {
        this.message = 'Please correct the errors in the form';
        return;
      }

      this.loading = true;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/register`, {
          username: this.email,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password
        });
        //this.message = response.data.message;
//        this.$router.push('/kit-registered');

        if (response.status === 200 && response.data.message === "Registration successful. Please check your email for verifying your email.") {
          this.$router.push('/registration-successful');
        }
      } catch (error) {
        this.message = 'Error: ' + error.response.data.error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
