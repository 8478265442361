<template>
  <div class="uk-container login-user">
    <UcariHeader />
    <h1>Password Reset</h1>
    <form @submit.prevent="requestPasswordReset">
      <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="message">{{ message }}</div>
      <div>
        <label for="email">Email</label>
        <input class="uk-input" type="email" v-model="email" />
      </div>
      <button type="submit" class="uk-button uk-button-primary uk-margin">Send Reset Link</button>
      <div><router-link to="/login">Return to Sign In</router-link></div>
    </form>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  name: 'RequestPasswordReset',
  created() {
    document.title = 'Password Reset | UCARI';
  },
  components: {
    UcariHeader,  
    UcariFooter
  },
  data() {
    return {
      email: '',
      message: '',
      error: ''
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/request-password-reset`, { email: this.email });
        this.message = response.data.message;
        this.error = '';
        this.$router.push('/request-password-reset-successful');
      } catch (error) {
        this.error = error.response?.data?.error || 'An error occurred';
        this.message = '';
      }
    }
  }
};
</script>
