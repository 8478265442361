<template>
  <footer class="uk-text-center uk-margin-top uk-margin-bottom">
    <p class="uk-text-small">The UCARI test and this website does not provide a medical diagnosis. Reliance on any information provided by UCARI and this website is solely at your own risk. All of the information provided in and throughout this website is intended solely for general, educational purposes, but is not to provide a particular diagnosis, treatment, or promote a system of care.</p>

    <div class="uk-text-small">
      <span  class="uk-link-text uk-margin-right" >© 2024 UCARI. All rights reserved.</span>
      <a href="https://ucari.com/termsofservice" class="uk-link-text uk-margin-right" target="_blank">Terms of service</a>
      <a href="https://ucari.com/privacy" class="uk-link-text" target="_blank">Privacy policy</a>
    </div>
  </footer>
</template>
