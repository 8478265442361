<template>
  <div class="uk-container login-user">
    <UcariHeader />
    <h1>Reset Password</h1>
    <form @submit.prevent="resetPassword">
      <div v-if="message">{{ message }}</div>
      <div v-if="error">{{ error }}</div>
      <span v-if="resetLink">
        <a :href="resetLink">Request new password reset link</a>
      </span>      
      <div class="uk-margin">
        <label for="newPassword">New Password</label>
        <div class="uk-inline">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="uk-input"
            v-model="newPassword"
            @input="handlePasswordInput"
          />
          <a class="uk-form-icon uk-form-icon-flip" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</a>
        </div>
        <div class="password-requirements">
          <div><strong>Password must contain:</strong></div>
          <ul>
            <li :class="{ 'valid': passwordHasLowercase }">One lowercase letter</li>
            <li :class="{ 'valid': passwordHasUppercase }">One uppercase letter</li>
            <li :class="{ 'valid': passwordHasNumber }">One number</li>
            <li :class="{ 'valid': passwordHasSpecialChar }">One of these special character: ! @ # $ % ^ & * ( ) - + { } | ?</li>
            <li :class="{ 'valid': passwordHasLength }">At least 8 characters</li>
          </ul>
        </div>
      </div>
      <div class="uk-margin">
        <label for="confirmPassword">Confirm New Password</label>
        <div class="uk-inline">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            class="uk-input"
            v-model="confirmNewPassword"
            @input="handlePasswordInput"
          />
          <a class="uk-form-icon uk-form-icon-flip" @click="toggleConfirmPasswordVisibility">{{ showConfirmPassword ? 'Hide' : 'Show' }}</a>
        </div>
        <div class="error" v-if="passwordMismatch">{{ passwordMismatch }}</div>
      </div>
      <button type="submit" class="uk-button" :disabled="isFormInvalid">Reset Password</button>
    </form>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 
import { validatePassword, toggleVisibility } from '@/utils/common';

export default {
  components: {
    UcariHeader,  
    UcariFooter
  },
  data() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      message: '',
      error: '',
      showPassword: false,
      showConfirmPassword: false,
      isPasswordInvalid: false,
      passwordMismatch: ''
    };
  },
  computed: {
    isFormInvalid() {
      return this.isPasswordInvalid || this.passwordMismatch || !this.newPassword || !this.confirmNewPassword;
    },
    passwordHasLowercase() {
      return /[a-z]/.test(this.newPassword);
    },
    passwordHasUppercase() {
      return /[A-Z]/.test(this.newPassword);
    },
    passwordHasNumber() {
      return /\d/.test(this.newPassword);
    },
    passwordHasSpecialChar() {
      return /[!@#$%^&*()\-+{}|:;<>?/\\]/.test(this.newPassword);
    },
    passwordHasLength() {
      return this.newPassword.length >= 8;
    }
  },
  methods: {
    handlePasswordInput() {
      const { isPasswordInvalid, passwordMismatch } = validatePassword(this.newPassword, this.confirmNewPassword);
      this.isPasswordInvalid = isPasswordInvalid;
      this.passwordMismatch = passwordMismatch;
    },
    togglePasswordVisibility() {
      this.showPassword = toggleVisibility(this.showPassword);
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = toggleVisibility(this.showConfirmPassword);
    },
    async resetPassword() {
      if (this.isFormInvalid) {
        this.error = 'Please correct the errors in the form';
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/reset-password`, {
          token: this.$route.query.token,
          newPassword: this.newPassword
        });
        this.message = response.data.message;
        this.error = '';
        this.$router.push('/reset-password-successful');        
      } catch (error) {
        if (error.response?.data?.error === 'Token has expired') {
          this.error = 'Your token has expired.';
          this.resetLink = 'https://account.ucari.com/request-password-reset';
        } else {
          this.error = error.response?.data?.error || 'An error occurred';
        }
        this.message = '';
      }
    }
  }
};
</script>
