<template>
  <div class="uk-container login-user">
    <div class="uk-margin-top uk-margin-bottom">
      <a href="https://ucari.com/">
          <img src="/images/logo.png" alt="UCARI Logo" width="100">
      </a>
    </div>

    <h1>Email Verification</h1>
    <div>
      <div v-if="loading">Verifying your email...</div>
      <div v-if="error">{{ error }}</div>
    </div>
    <div v-if="success">
      <div>{{ success }}</div>
      <div class="uk-margin-top">
        <router-link to="/login">
          <button class="uk-button">Sign In</button>
        </router-link>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      error: null,
      success: null,
    };
  },
  async created() {
    const token = this.$route.query.token;

    if (!token) {
      this.error = 'Verification token is missing.';
      this.loading = false;
      return;
    }

    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/verify-email?token=${token}`);
      this.success = response.data.message;
    } catch (error) {
      this.error = error.response?.data?.error || 'An error occurred during email verification.';
    } finally {
      this.loading = false;
    }
  },
};
</script>
