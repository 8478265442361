<template>
  <div class="uk-container register-kit">
    <UcariHeader />
    <h1>Password Reset</h1>
    <p>Your password was successfully updated.</p>
    <router-link to="/login">
      <button class="uk-button">Sign In</button>
    </router-link>
  </div>
  <UcariFooter />
</template>

<script>
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  name: 'RequestPasswordReset',
  created() {
    document.title = 'Password Reset | UCARI';
  },
  components: {
    UcariHeader,  
    UcariFooter
  }
}
</script>