<template>
  <div v-if="localItem" id="edit-profile-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title">Edit Details</h2>
      
      <div v-if="localItem.TestKitType === 'Pet'">
        <div class="uk-margin">
          <label for="pet-name">Pet Name</label>
          <input id="pet-name" v-model="localItem.PetName" class="uk-input" type="text" placeholder="Pet Name">
        </div>

        <div class="uk-margin">
          <label for="last-name" class="uk-margin-top">Family Last Name</label>
          <input id="last-name" v-model="localItem.LastName" class="uk-input" type="text" placeholder="Family Last Name">
        </div>

        <div class="uk-margin">
          <label class="uk-form-label" for="form-stacked-select">Pet Type</label>
          <div class="uk-form-controls">
            <label>
              <input class="uk-radio" v-model="localItem.petType" type="radio" name="petType" value="Dog" checked> Dog
            </label>
            <label>
              <input class="uk-radio uk-margin-left" v-model="localItem.petType" type="radio" value="Cat" name="petType"> Cat
            </label>
          </div>
        </div>
      </div>      
      <div v-else>
        <div class="uk-margin">
          <label for="first-name">First Name</label>
          <input id="first-name" v-model="localItem.FirstName" class="uk-input" type="text" placeholder="Enter First Name">
        </div>
        
        <div class="uk-margin">
          <label for="last-name" class="uk-margin-top">Last Name</label>
          <input id="last-name" v-model="localItem.LastName" class="uk-input" type="text" placeholder="Enter Last Name">
        </div>
        
        <div class="uk-margin">
          <label for="gender" class="uk-margin-top">Gender</label>
          <select id="gender" v-model="localItem.Gender" class="uk-select">
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div>
        <button class="uk-button uk-button-primary uk-margin-small-right" @click="saveChanges">Save</button>
        <button class="uk-button uk-button-cancel" @click="cancelEdit">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localItem: null,
      accessToken: localStorage.getItem('accessToken'),
      cid: localStorage.getItem('cid'),
      formSubmitted: false,
      errorMessage: '',
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        if (newItem) {
          // Create a copy of the item to avoid direct prop mutation
          this.localItem = { ...newItem };
        }
      },
    },
  },
  methods: {
    cancelEdit() {
      this.$emit('close'); // Emit close event to parent
      //UIkit.modal('#edit-profile-modal').hide(); // Hide the modal
    },
    async saveChanges() {
      this.formSubmitted = true;
      // const petType = this.localItem.TestKitType === 'Pet' ? 'Dog' : null;

      try {
        const userInfo = {
          kitId: this.localItem.KitId,
          newPetName: this.localItem.PetName,
          newFirstName: this.localItem.FirstName,
          newLastName: this.localItem.LastName,
          newGender: this.localItem.Gender,
          newPetType: this.localItem.petType,
        };
        await axios.post(`${process.env.VUE_APP_API_URL}/api/update-profile`, userInfo, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid,
          },
        });
        this.$emit('item-updated', this.localItem); // Emit updated item to parent
        //UIkit.modal('#edit-profile-modal').hide(); // Hide the modal after saving
      } catch (error) {
        this.errorMessage = 'Failed to register kit: ' + (error.response?.data?.error || error.message);
      }
    },
  },
};
</script>
