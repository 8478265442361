<template>
  <div class="uk-container login-user">
    <UcariHeader />
    <form @submit.prevent="login">
      <h2>Sign In</h2>
      <p>Sign in to register a kit and view your test results. <b>If you registered before July 9, 2024, 11 AM EST, please <router-link to="/request-password-reset">reset your password</router-link>, as we have updated our security requirements</b>.</p>
      <div v-if="message" class="error">
        <p>{{ message }}</p>
      </div>
      <div>
        <div class="uk-margin">
          <label for="email">Email</label>
          <input class="uk-input" 
                 :class="{ 'error-border': (isEmailInvalid || !email) && (emailTouched || formSubmitted) }" 
                 type="email" 
                 v-model="email" 
                 @blur="validateEmail" 
                 @focus="emailTouched = true" 
                 />
          <div class="error" v-if="emailMessage">{{ emailMessage }}</div>
        </div>

        <div class="uk-margin">
          <label for="password">Password</label>
          <div class="uk-inline">
            <input :type="showPassword ? 'text' : 'password'" 
                   class="uk-input" 
                   :class="{ 'error-border': (isPasswordInvalid || !password) && (passwordTouched || formSubmitted) }" 
                   v-model="password" 
                   @input="handlePasswordInput" 
                   @focus="passwordTouched = true" 
                   />
            <a class="uk-form-icon uk-form-icon-flip" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</a>
          </div>
        </div>

        <div>
          By creating an account, you agree to our <a href="https://ucari.com/termsofservice" target="_blank">Terms of service</a> and <a href="https://ucari.com/privacy" target="_blank">Privacy policy</a>.
        </div>
        <button class="uk-button uk-button-primary uk-margin" type="submit" :disabled="isFormInvalid || loading">
          Sign In
          <span v-if="loading" class="uk-margin-small-left" uk-spinner="ratio: 0.6"></span>
        </button>
        <div>Don't have an account? <router-link to="/register">Create Account</router-link></div>
        <div>Forgot your password? <router-link to="/request-password-reset">Reset Password</router-link></div>
      </div>
    </form>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  created() {
    document.title = 'Sign In | UCARI';
    this.redirectUrl = this.$route.query.redirect || '/';
  },  
  components: {
    UcariHeader,  
    UcariFooter
  },
  data() {
    return {
      email: '',
      password: '',
      message: '',
      isEmailInvalid: false,
      isPasswordInvalid: false,
      showPassword: false,
      emailTouched: false,
      passwordTouched: false,
      formSubmitted: false,
      loading: false,
      redirectUrl: ''
    };
  },
  computed: {
    isFormInvalid() {
      return this.isEmailInvalid || this.isPasswordInvalid || !this.email || !this.password;
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      this.isEmailInvalid = !re.test(this.email);
      if (this.isEmailInvalid) {
        this.emailMessage = 'Invalid email address';
      } else {
        this.emailMessage = '';
      }
    },
    handlePasswordInput() {
      this.isPasswordInvalid = !this.password;
    },
    async login() {
      this.formSubmitted = true;
      this.validateEmail();
      this.handlePasswordInput();

      if (this.isFormInvalid) {
        this.message = 'Please correct the errors in the form';
        return;
      }

      this.loading = true;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/login?redirect=${encodeURIComponent(this.redirectUrl)}`, {
          email: this.email,
          password: this.password
        });

        if (response.status === 200) {
          const token = response.data.token;
          // const first = response.data.first;
          const cid = response.data.cid;
          if (token) {
            localStorage.setItem('accessToken', token);
            localStorage.setItem('cid', cid);
          }
          // const redirectUrl = response.data.redirectUrl || '/';
          this.$router.push("/");
        } else if (response.status === 400) {
          this.message = response.data.error;
        } else {
          this.message = response.data.message || 'Sign in failed';
        }
      } catch (error) {
        this.message = (error.response?.data?.error || error.message);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
