<template>
  <div class="uk-container register-kit">
    <UcariHeader />
    <h1>Password Reset</h1>
    <p>Password reset link sent. Please check your email and click the link to continue.</p>
  </div>
  <UcariFooter />
</template>

<script>
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  name: 'RequestPasswordReset',
  created() {
    document.title = 'Password Reset | UCARI';
  },
  components: {
    UcariHeader,  
    UcariFooter
  }
}
</script>