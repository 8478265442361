// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import store from './store';
import router from './router';

const app = createApp(App);
app.use(store); // Use the Vuex store
app.use(router); // Use the router
app.mount('#app'); // Mount the app

axios.defaults.withCredentials = true;
