<template>
  <div class="uk-container register-kit">
    <UcariHeader />
    <div class="uk-margin-bottom">
      <a href="/" uk-icon="left-arrow">&lt; My Account</a>
    </div>

    <h1>Register Your Test Kit</h1>
    
    <form @submit.prevent="validateKitId">
      <p>Please enter your Kit ID located below on your collection bag inside your test kit box.</p>
      <div class="register-kit-sample-bar-code">
        <img src="/images/sample-kitId-bar-code.png" alt="ucari-test-kit-sample-bar-code" />
      </div>

      <div v-if="errorMessage" class="error uk-margin-top">{{ errorMessage }}</div>

      <div class="uk-margin">
        <label for="serialNumber">Kit Id</label>
        <div>
        <input 
          class="uk-input serial-number" 
          :class="{ 'error': formSubmitted && !isValidSerialNumber && formattedSerialNumber.length > 0 }"
          type="text" 
          v-model="formattedSerialNumber" 
          @input="formatSerialNumber" 
          id="serialNumber" 
          placeholder="XXXXXX-XXXXXX"
          />
          </div>
      </div>
      <button class="uk-button" type="submit" :disabled="!isValidSerialNumber">Continue</button>
    </form>
    
    <div class="uk-margin"></div>

    <div v-if="isValidKit">
      <form @submit.prevent="submitUserInfo" class="uk-margin">
        <h3>Enter User Information</h3>
        <div v-if="kitType === 'Pet'">
          <div>
            <label for="petName">Pet Name</label>
            <input class="uk-input" type="text" v-model="petName" :class="{ 'error': formSubmitted && !petName }" id="petName" />
          </div>
        </div>
        <div v-if="kitType !== 'Pet'" class="uk-margin">
          <div>
            <label for="firstName">First Name</label>
            <input class="uk-input" type="text" v-model="firstName" :class="{ 'error': formSubmitted && !firstName }" id="firstName" />
          </div>
        </div>
        <div class="uk-margin">
          <div>
            <label for="lastName">
              <span v-if="kitType === 'Pet'">Family </span>Last Name
            </label>
            <input class="uk-input" type="text" v-model="lastName" :class="{ 'error': formSubmitted && !lastName }" id="lastName" />
          </div>
        </div>
        <!--
        <div class="uk-margin">
          <div>
            <label for="birthDate">Date of Birth</label>
            <input
              class="uk-input" 
              type="date" 
              v-model="birthDate" 
              id="birthDate" 
              :class="{ 'error': formSubmitted && (dobError || !birthDate) }"
              :max="maxDate"
              />
            <div v-if="dobError" class="uk-text-danger">{{ dobError }}</div>
          </div>
        </div>
        -->
        <div v-if="kitType !== 'Pet'" class="uk-margin">
          <label class="uk-form-label" for="form-stacked-select">Gender</label>
          <div class="uk-form-controls">
            <select class="uk-select" v-model="gender" :class="{ 'error': formSubmitted && !gender }" id="form-stacked-select">
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="RatherNotSay">Other</option>
            </select>
          </div>
        </div>        
        <div v-if="kitType === 'Pet'" class="uk-margin">
          <label class="uk-form-label" for="form-stacked-select">Pet Type</label>
          <div class="uk-form-controls">
            <label>
              <input class="uk-radio" v-model="petType" type="radio" name="petType" value="Dog" checked> Dog
            </label>
            <label>
              <input class="uk-radio uk-margin-left" v-model="petType" type="radio" value="Cat" name="petType"> Cat
            </label>
          </div>
        </div>       
        <button class="uk-button" type="submit" @click="formSubmitted = true">Register Kit</button>
      </form>
    </div>    
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  name: 'RegisterKit',
  created() {
    document.title = 'Register Kit | UCARI';
    this.accessToken = localStorage.getItem('accessToken');
    this.cid = localStorage.getItem('cid');
  },  
  components: {
    UcariHeader,  
    UcariFooter
  },
  data() {
    return {
      serialNumber: '',
      formattedSerialNumber: '',
      isValidKit: false,
      kitId: '',
      kitType: '',
      petName: '',
      petType: 'Dog',
      firstName: '',
      lastName: '',
      dobError: '',
      menu: false,
      maxDate: new Date().toISOString().substr(0, 10), // Today's date
      gender: '',
      errorMessage: '',
      cid: '',
      accessToken: '',
      isValidSerialNumber: false,
      formSubmitted: false // New data property to track form submission
    };
  },
  methods: {
    formatSerialNumber(event) {
      let input = event.target.value;
      input = input.replace(/[^A-Za-z0-9]/g, '').substring(0, 12);

      if (input.length > 6) {
        input = input.slice(0, 6) + '-' + input.slice(6);
      }

      this.formattedSerialNumber = input;
      this.validateSerialNumber();
    },
    validateSerialNumber() {
      const pattern = /^[A-Za-z0-9]{6}-[A-Za-z0-9]{6}$/;
      this.isValidSerialNumber = pattern.test(this.formattedSerialNumber);
    },
    async validateKitId() {
      if (this.formattedSerialNumber === '') {
        this.errorMessage = 'Kit Id is required.';
        this.isValidSerialNumber = false;
        return;
      }

      if (!this.isValidSerialNumber) {
        this.errorMessage = 'Invalid Kit Id. Please enter in the format XXXXXX-XXXXXX.';
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/validate-kit`, { serialNumber: this.formattedSerialNumber }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid            
          }
        });
        if (response.data.isValid) {
          this.isValidKit = true;
          this.kitType = response.data.kitType;
          this.kitId = response.data.kitId;
          this.errorMessage = '';
        } else {
          this.isValidKit = false;
          this.errorMessage = 'Invalid Kit ID';
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error === 'Already Registered') {
          this.errorMessage = 'Kit ID is already registered';
        } else {
          this.errorMessage = 'An error occurred during validation';
        }
      }
    },
    async submitUserInfo() {
      this.formSubmitted = true;
      let petType = (this.kitType === 'Pet') ? 'Dog' : null;

      try {
        const userInfo = {
          kitId: this.kitId,
          petName: this.petName,
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
          petType: petType
        };
        await axios.post(`${process.env.VUE_APP_API_URL}/api/register-kit`, userInfo, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid            
          },
          method: 'POST',
        });
        this.$router.push('/kit-registered');
      } catch (error) {
        this.errorMessage = 'Failed to register kit: ' + (error.response?.data?.error || error.message);
      }
    }
  }  
};
</script>
