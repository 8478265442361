import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    isAuthenticated: false,
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        await axios.post('/api/login', credentials);
        commit('setAuthentication', true);
      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post('/api/logout');
        commit('setAuthentication', false);
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    checkAuthentication({ commit }) {
      // Here you can implement a logic to check if the user is authenticated,
      // for example by checking a token in localStorage or a cookie
      const isAuthenticated = !!localStorage.getItem('auth_token');
      commit('setAuthentication', isAuthenticated);
    },
  },
});

export default store;
