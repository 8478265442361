<template>
  <div class="uk-container welcome">
    <UcariHeader />

    <div class="uk-text-center">
      <h1>Welcome to Ucari</h1>
    </div>

    <div class="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@m uk-grid uk-margin-top" uk-grid>
      <div>
        <div class="uk-section uk-section-default uk-text-center welcome-register">
          <h2>First Time Here?</h2>
          <p>Create a free account to register a kit and view your test results.</p>
          <div>
            <router-link to="/register">
              <button class="uk-button">Create Account</button>
            </router-link>
          </div>
        </div>
      </div>

      <div>
        <div class="uk-section uk-section-muted uk-text-center welcome-login">
          <h2>Already Have An Account?</h2>
          <p class="uk-very-small">Sign in to register a kit and view your test results.</p>
          <div>
            <router-link to="/login">
              <button class="uk-button">Sign In</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <UcariFooter />
</template>

<script>
import UcariHeader from './UcariHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  created() {
    document.title = 'Welcome | UCARI';
  },
  components: {
    UcariHeader,
    UcariFooter
  }
};
</script>
