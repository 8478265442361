<template>
  <div>
    <nav class="uk-navbar-container uk-navbar" uk-navbar>
      <div class="uk-navbar-left">
        <a class="uk-navbar-item uk-logo" href="https://ucari.com/">
          <img src="/images/logo.png" alt="UCARI Logo" width="100">
        </a>
      </div>
      <div class="uk-navbar-right no-print">
        <ul class="uk-navbar-nav">
          <li v-if="isAuthenticated">
            <a @click="logout">Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isAuthenticated: false,
      firstName: '',
      cid: '',
      accessToken: ''
    };
  },
  created() {
    this.loadCredentials();
    this.checkAuthentication();
  },
  methods: {
    loadCredentials() {
      this.accessToken = localStorage.getItem('accessToken');
      this.cid = localStorage.getItem('cid');
    },
    async checkAuthentication() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/check-auth`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          }
        });
        this.isAuthenticated = response.data.authenticated;
      } catch (err) {
        console.error('Session check failed', err);
        this.isAuthenticated = false;
      }
    },
    async logout() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/logout`);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('cid');
        this.isAuthenticated = false;
        this.$router.push('/login');
      } catch (err) {
        console.error('Logout failed', err);
      }
    },
    isActive(path) {
      return this.$route.path === path;
    }
  }
};
</script>
